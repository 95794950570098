import styled from 'styled-components'

import EquationCross from 'components/core/icons/factory/EquationCross'
import EquationEquals from 'components/core/icons/factory/EquationEquals'
import EquationRootTail from 'components/core/icons/factory/EquationRootTail'
import { device } from 'components/draw/constants'
import { VerticalSpacer } from 'components/core/Spacer'

const GooRightDetails = styled.div`
  display: flex;
  margin: 32px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  margin: 0px 0;
  flex-direction: row;
  gap: 0px;

  @media (max-width: 1000px) {
    font-size: 13px;
  }
`

const GooEquationContainer = styled.div`
  display: flex;
  position: relative;
  width: 232px;

  @media (max-width: 1000px) {
    width: 173px;
  }
`

const EquationRootContainer = styled.div`
  position: absolute;
  pointer-events: none;
  left: 0;
  width: 100%;
  overflow: hidden;

  path {
    stroke: #eaebf1;
  }
`

const EquationRootTailConatiner = styled.div`
  position: absolute;
  pointer-events: none;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  path {
    stroke: #eaebf1;
  }
`

const EquationLeft = styled.div`
  margin-left: 22px;
  margin-top: 10px;
  font-weight: 600;

  @media ${device.laptopMax} {
    margin-left: 22px;
  }
`

const EquationRight = styled.div`
  // margin-right: 100px;
  margin-top: 10px;
  font-weight: 600;
`

const EquationCrossContainer = styled.div`
  margin-top: 12px;
  margin-left: 6px;
  margin-right: 6px;

  path {
    fill: #b8bccf;
  }
`

const EquationEqualsContainer = styled.div`
  path {
    fill: #b8bccf;
  }
  margin: 0 14px;

  filter: drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.64));
`

const EquationTotal = styled.div`
  font-size: 18px;
  background-color: #8ef42e;
  color: black;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 12px;

  @media (max-width: 1000px) {
    font-size: 13px;
    padding: 6px 15px;
  }
`

const GooBottomRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

function GooPlaceholderEquationHome({ small = false }: any) {
  return (
    <GooRightDetails>
      <GooEquationContainer>
        <EquationLeft>
          <div>Goo staked</div>
        </EquationLeft>
        <EquationCrossContainer>
          <EquationCross size={small ? 12 : 15} />
        </EquationCrossContainer>
        <EquationRight>
          <div>Multiplier</div>
        </EquationRight>

        <EquationRootContainer>
          <EquationRootHome width={small ? 170 : 233} height={small ? 20 : 35} />
        </EquationRootContainer>
      </GooEquationContainer>
      <GooBottomRightContainer>
        <EquationEqualsContainer>
          <EquationEquals size={small ? 12 : 15} />
        </EquationEqualsContainer>
        <EquationTotal>Daily Rate</EquationTotal>
      </GooBottomRightContainer>
    </GooRightDetails>
  )
}

export default GooPlaceholderEquationHome

function EquationRootHome({ width = 233, height = 35 }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="233"
      height="35"
      fill="none"
      viewBox="0 0 233 35"
    >
      <g clipPath="url(#clip0_2671_15246)">
        <path
          stroke="#EAEBF1"
          strokeWidth="2.328"
          d="M.621 19.811h7.37l3.88 9.799 5.431-27.155h211.423v10.3"
        ></path>
      </g>
      <g clipPath="url(#clip1_2671_15246)">
        <path stroke="#EAEBF1" strokeWidth="2.328" d="M16.14 2.455h207.155"></path>
      </g>
      <g clipPath="url(#clip2_2671_15246)">
        <path
          stroke="#EAEBF1"
          strokeWidth="2.328"
          d="M2.174 19.811h7.37l3.88 9.799 5.43-27.155h211.423v10.3"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2671_15246">
          <path fill="#fff" d="M0 0H20.948V33.362H0z" transform="translate(.621 .904)"></path>
        </clipPath>
        <clipPath id="clip1_2671_15246">
          <path fill="#fff" d="M0 0H201.724V33.362H0z" transform="translate(21.57 .904)"></path>
        </clipPath>
        <clipPath id="clip2_2671_15246">
          <path
            fill="#fff"
            d="M0 0H9.31V33.362H0z"
            transform="matrix(-1 0 0 1 232.604 .904)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}
