import type { art_gobbler_app_users } from '@prisma/client'
import { DEFAULT_ANONMYOUS_NAME } from 'config'

export const getDisplayNameFromGobblerAuthAccount = (
  hydratedAccount: art_gobbler_app_users | null | undefined,
  shortenAddress = false,
) => {
  let walletAddress = hydratedAccount?.wallet_address
  if (shortenAddress && hydratedAccount?.wallet_address) {
    walletAddress = `${hydratedAccount.wallet_address.slice(
      0,
      6,
    )}...${hydratedAccount.wallet_address.slice(-4)}`
  }

  return (
    hydratedAccount?.wallet_ens_name ??
    hydratedAccount?.twitter_handle ??
    walletAddress ??
    DEFAULT_ANONMYOUS_NAME
  )
}

export const hydrateLinkedAccountData = (
  mainAccount: art_gobbler_app_users | null | undefined,
  linkedAccount: art_gobbler_app_users | null | undefined,
): art_gobbler_app_users | null => {
  if (!mainAccount) {
    return null
  }
  // Hydrate account
  if (linkedAccount) {
    // If linked account is an admin, let's also set the main account to admin implicitly
    if (linkedAccount.is_admin) {
      mainAccount.is_admin = true
    }
    if (linkedAccount.twitter_id) {
      mainAccount.twitter_id = linkedAccount.twitter_id
      mainAccount.twitter_handle = linkedAccount.twitter_handle
      mainAccount.twitter_name = linkedAccount.twitter_name
      mainAccount.twitter_profile_img_src = linkedAccount.twitter_profile_img_src
    } else if (linkedAccount.wallet_address) {
      mainAccount.wallet_address = linkedAccount.wallet_address
      mainAccount.wallet_ens_name = linkedAccount.wallet_ens_name
    }
  }
  return mainAccount
}

export const getUserAccountsIds = (mainAccount: art_gobbler_app_users | null | undefined) => {
  if (!mainAccount) {
    return []
  }
  const ids = []
  if (mainAccount?.id) {
    ids.push(mainAccount.id)
  }
  if (mainAccount?.linked_account_linked_id) {
    ids.push(mainAccount.linked_account_linked_id)
  }
  return ids
}
