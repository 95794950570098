import styled from 'styled-components'

export const ContainerOuterDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`

export const ContainerInnerDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: flex-start;
  padding: 0 20px;
  max-width: 1280px;
  z-index: 1;
`

function MaxWidthLayout({ children }: { children: React.ReactNode }) {
  return (
    <ContainerOuterDiv>
      <ContainerInnerDiv>{children}</ContainerInnerDiv>
    </ContainerOuterDiv>
  )
}

export default MaxWidthLayout
