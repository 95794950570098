import { ILayer } from './types'

export const DEFAULT_LAYER: ILayer = {
  name: 'Layer 0',
  id: 'layer-0',
  visible: true,
  groups: [{ x: 0, y: 0, shapes: [] }],
  isTextLayer: false,
}

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '800px',
  laptop: '1033px',
  laptopL: '1266px',
  desktop: '2560px',
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  // Laptop
  laptop: `(min-width: ${size.laptop})`,
  laptopMax: `(max-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}

export const MIN_CANVAS_SIZE = 200
export const MAX_CANVAS_SIZE = 800
export const MAX_NUM_LAYERS = 5
export const MAX_STATE_LENGTH = 10
export const MAX_LOCAL_UNSAVED_CHANGES = 50
export const MAX_UNSAVED_EDITS = 40
export const MAX_TOTAL_EDITS = 2001
export const FIFTY_PERC_EDITS_COUNT = Math.ceil(MAX_TOTAL_EDITS / 2)
export const SEVENTY_FIVE_PERC_EDITS_COUNT = Math.ceil(
  MAX_TOTAL_EDITS - Math.ceil(MAX_TOTAL_EDITS / 4),
)

export const NUM_TO_TAKE_DURING_LONG_SAVE = 100
export const LAST_STABLE_DRAW_TOOL_VERSION = 4
export const DRAW_TOOL_VERSION = '9'

export const MIN_SCREEN_WIDTH = 860
export const MIN_SCREEN_HEIGHT = 500

export const DEFAULT_SAVE_INTERVAL = 8000

export const SAVED_DRAWINGS_ID_ARRAY_KEY = 'saved_drawing_ids'
export const ARCHIVED_SAVED_DRAWINGS_ID_ARRAY_KEY = 'archived_saved_drawing_ids'

export const CURRENT_DRAWING_ID = 'current_drawing_id'
export const ACCESS_TOKEN_ID_KEY = 'access_token'

export const GENERIC_ERROR_MESSAGE = 'Something went wrong, please ping @addy on Discord'

export const DEFAULT_ARTWORK_TITLE = 'Untitled Drawing'
