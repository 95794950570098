import styled from 'styled-components'

const RowAlignedCenter = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`

const InsetContainer = styled.div`
  padding: 24px;

  background: #1f2025;
  /* Black */

  border: 2px solid #080a0c;
  /* Inset Shadow */

  box-shadow: 0px 2px 3px rgba(255, 255, 255, 0.16), inset 0px 2px 2px rgba(0, 0, 0, 0.45);
  border-radius: 16px;
  box-sizing: border-box;
`

const GobblerChartContainer = styled.div`
  position: relative;
  background: #14280b;
  max-width: 360px;
  overflow: hidden;
  margin: 0 auto;
  border: 6px solid #080a0c;
  box-shadow: -1px -3px 4px rgba(255, 255, 255, 0.2), 0px 16px 32px rgba(27, 26, 37, 0.24),
    0px 12px 24px rgba(27, 26, 37, 0.16), 0px 4px 8px rgba(27, 26, 37, 0.12);
  border-radius: 32px;
`

const GobblerChartContainerPositionedAbsoluteCenter = styled(GobblerChartContainer)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: calc(1280px);
  margin: 0 auto;
  padding: 0 16px;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  padding: 0 32px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 10px;
  `}
`

export {
  ContentWrapper,
  RowAlignedCenter,
  InsetContainer,
  GobblerChartContainer,
  GobblerChartContainerPositionedAbsoluteCenter,
}
