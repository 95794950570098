export default function EquationCross({ size = 20 }: any) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.48928 14.5994L2.42862 15.66L4.54994 17.7813L5.6106 16.7207L3.48928 14.5994ZM16.5118 5.81945L17.5725 4.75879L15.4512 2.63747L14.3905 3.69813L16.5118 5.81945ZM5.6106 16.7207L16.5118 5.81945L14.3905 3.69813L3.48928 14.5994L5.6106 16.7207Z"
        fill="#9C9DA8"
      />
      <path
        d="M16.5127 14.5994L17.5733 15.66L15.452 17.7813L14.3914 16.7207L16.5127 14.5994ZM3.49012 5.81945L2.42946 4.75879L4.55078 2.63747L5.61144 3.69813L3.49012 5.81945ZM14.3914 16.7207L3.49012 5.81945L5.61144 3.69813L16.5127 14.5994L14.3914 16.7207Z"
        fill="#9C9DA8"
      />
    </svg>
  )
}
