import styled from 'styled-components'

const PageView = styled.div`
  position: relative;
  min-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  min-width: 100%;
  background: #2f3035;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  flex: 1;
`

const ContentContainer = styled.div`
  max-width: calc(1116px + 40px);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 390px;
  grid-gap: 80px;
  padding-left: 20px;
  padding-right: 20px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-gap: 60px;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display:flex;
    flex-direction: column;
    grid-gap: 0;
  `}
`

const ContentContainerSingleRow = styled(ContentContainer)`
  grid-template-columns: 1fr;
`

/**
 * The main pane, usually holds the main image/thing
 */
const PrimaryPane = styled.div`
  position: relative;
`

/**
 * Usually holds the metadata and actions
 */
const SecondaryPane = styled.div``

export { PageView, ContentContainer, ContentContainerSingleRow, PrimaryPane, SecondaryPane }

/**
 * <PageView>
 *   <ContentContainer>
 *     <PrimaryPane>
 *        <img src={''} />
 *     </PrimaryPane>
 *    <SecondaryPane>
 *        <div>foo</div>
 *    </SecondaryPane>
 *   </ContentContainer>
 * </>
 */
