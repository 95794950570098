export default function EquationEquals({ size = 20 }: any) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16732 12.667L2.66732 12.667L2.66732 15.667L4.16732 15.667L4.16732 12.667ZM15.834 15.667L17.334 15.667L17.334 12.667L15.834 12.667L15.834 15.667ZM4.16732 15.667L15.834 15.667L15.834 12.667L4.16732 12.667L4.16732 15.667Z"
        fill="#9C9DA8"
      />
      <path
        d="M15.8327 4.33301L17.3327 4.33301L17.3327 7.33301L15.8327 7.33301L15.8327 4.33301ZM4.16602 7.33301L2.66602 7.33301L2.66602 4.33301L4.16602 4.33301L4.16602 7.33301ZM15.8327 7.33301L4.16602 7.33301L4.16602 4.33301L15.8327 4.33301L15.8327 7.33301Z"
        fill="#9C9DA8"
      />
    </svg>
  )
}
