import { TwitterTweetIcon } from 'components/draw/styles'
import styled from 'styled-components'
import { RowAlignedCenter } from './layout/Common'
import { ExternalLink, StyledLink } from './Links'
import Image from 'next/image'
import useIsMobile from 'hooks/util/useIsMobile'
import { cdnRoot } from 'utils/cdn'
import Link from 'next/link'
import { Spacer } from './Spacer'
import useWindowSize from 'components/draw/hooks/useWindowSize'
import MaxWidthLayout from './layout/MaxWidthLayout'

const black = '#080A0C'

const Footer = () => {
  const { width, height } = useWindowSize()

  const isMobile = useIsMobile()
  return (
    <RowAlignedCenter
      style={{
        position: 'relative',
        display: 'flex',
        // flex: 1,
        flexDirection: 'row',
        width: '100%',
        color: black,
        flex: 0,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        backgroundColor: '#F8FFC0',
        padding: '64px 0px',
      }}
    >
      <MaxWidthLayout>
        <FooterGrid>
          <FooterLeft>
            <div style={{ width: 171, marginRight: 16 }}>
              <Image
                style={{ width: 171 }}
                width={756}
                height={304}
                src={`${cdnRoot}/images/dark-logo-simple.png`}
              />
            </div>
            {width > 1000 && (
              <div>
                <div style={{ fontSize: 28, fontWeight: 700, color: '#080A0C', marginTop: 16 }}>
                  An experimental decentralized art factory
                </div>
                <div style={{ marginTop: 16 }}>
                  by <span style={{ fontWeight: 600 }}>Justin Roiland </span>and{' '}
                  <span style={{ fontWeight: 600 }}>Paradigm</span>
                </div>
              </div>
            )}
          </FooterLeft>

          <div></div>

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <div style={{ fontWeight: 600 }}>Contact</div>
            <Spacer size={20} />
            <FooterLink>
              <ExternalLink
                href={'https://twitter.com/artgobblers'}
                style={{ color: '#080a0c', fontWeight: 700 }}
              >
                Follow us on Twitter
              </ExternalLink>
            </FooterLink>

            <FooterLinkBorder></FooterLinkBorder>

            {/* <FooterLink>
              <ExternalLink
                href={'https://discord.gg/artgobblers'}
                style={{ color: '#080a0c', fontWeight: 700 }}
              >
                Join Discord
              </ExternalLink>
            </FooterLink>

            <FooterLinkBorder></FooterLinkBorder> */}

            <FooterLink>
              <ExternalLink
                href={'https://www.instagram.com/artgobblers/'}
                style={{ color: '#080a0c', fontWeight: 700 }}
              >
                Instagram
              </ExternalLink>
            </FooterLink>

            <FooterLinkBorder></FooterLinkBorder>

            {/**For John: Todo */}
            <FooterLink>
              <ExternalLink
                href={'mailto:contact@artgobblers.com'}
                style={{ color: '#080a0c', fontWeight: 700 }}
              >
                Email us
              </ExternalLink>
            </FooterLink>
          </div>
        </FooterGrid>
      </MaxWidthLayout>
    </RowAlignedCenter>
  )
}

const FooterGrid = styled.div`
  width: 100%;
  /* padding: 0 20px; */
  /* max-width: 1280px; */
  display: grid;
  grid-template-columns: 260px 1fr 500px;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    row-gap: 24px;
    justify-items: center;
    justify-content: center;
  }
`

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
  }
`

const FooterLink = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 110%;
  max-width: 500px;
  width: 500px;
  letter-spacing: -0.03em;
  color: #080a0c;

  @media (max-width: 1000px) {
    width: 90vw;
  }
`

const FooterLinkBorder = styled.div`
  border-bottom: 1px solid #fac74f;
  height: 1px;
  max-width: 500px;
  width: 500px;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    width: 90vw;
  }
`

export { Footer }
